import * as React from 'react'
import { css } from 'linaria'

import { SEO } from '../components/seo'
import 'normalize.css'

async function fetchColor() {
  const color = await fetch(`${process.env.GATSBY_GCFUNCTIONS}/randomColor`)
    .then(res => res.text())
  return color
}

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
  transition: background .3s ease;

  button {
    border: none;
    background: rgba(0,0,0,0.5);
    color: #fff;
    font-size: 1.2rem;
    line-height: 1;
    padding: 0.75em 1.25em;
    min-width: 10rem;
    border-radius: 2px;
    border-bottom: 2px solid rgba(0,0,0,0.6);
    text-shadow: 0px 2px rgba(0,0,0,0.6);
  }
`

type FetchingResult = "loading" | "ok" | "error"

const MSG: Record<FetchingResult, string> = {
  ok: 'New Color',
  error: 'Oops, failed',
  loading: 'Loading...',
}

const Sketch: React.FC = () => {
  const [ color, setColor ] = React.useState<string>('tomato')
  const [ state, setState ] = React.useState<FetchingResult>('ok')
  const handleClick: React.ReactEventHandler<HTMLButtonElement> = () => {
    setState('loading')
    fetchColor()
      .then(color => {
        setColor(color)
        setState('ok')
      })
      .catch(err => {
        setState('error')
        console.error(err)
      })
  }

  return (
    <>
      <SEO
        title={'Sketch: Google Cloud Functions with Gatsby'}
        meta={[{
          name: 'robots',
          content: 'noindex'
        }]}
      />
      <div className={styles} style={{ backgroundColor: color }}>
        <button 
          disabled={state === 'loading'} 
          onClick={handleClick}
        >
          {MSG[state]}
        </button>
      </div>
    </>
  ) 
}

export default Sketch